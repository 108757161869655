<template>
  <div>
    <div class="top">
      <el-page-header @back="goBack" :content="opertion_type == 'add' ? '创建送券计划' : '编辑送券计划'"></el-page-header>
    </div>
    <div class="main">
      <el-form label-width="150px" ref="form" :model="form" :rules="rules">
        <el-form-item prop="plan_name">
          <span slot="label">计划名称</span>
          <el-input class="flex w500" style="width: 600px;" v-model="form.plan_name"></el-input>
        </el-form-item>
        <!-- 发放方式 10：手动领取 20：首次关注赠送 30：注册赠送 40 消费赠送 50 生日赠送 60 纪念日赠送 70 自定义任务-->
        <el-form-item label="送券方式">
          <el-select v-model="form.give_type_seq" @change="getMessageData" :disabled="opertion_type == 'edit'">
            <el-option   v-for="(item, index) in giveSeqType" :key="index" :value="item.value" :label="item.label">{{
                item.label
            }}</el-option>
          </el-select>

        </el-form-item>

        <!-- 注册送券 -->
        <div v-if="form.give_type_seq == 30 || form.give_type_seq == 20">
          <el-form-item label="优惠券选择">
            <div class="flex">
              <el-select multiple v-model="form.coupon_rules[0].coupon_ids" @change="handleLimitCoupon">
                <el-option v-for="item in couponList" :key="item.coupon_id" :label="item.title_name"
                  :value="item.coupon_id + ''" :disabled="item.remain_num!='不限'&&item.remain_num<=0">
                  <span style="float: left">{{ item.title_name }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{' 剩余('+item.remain_num+')'}}</span>
                  </el-option>

              </el-select>

              <!-- <el-button class="ml-10" style="align-self:flex-start" type="primary" @click="handleAddCoupon">新增优惠券
              </el-button> -->
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">赠送张/次</span>
            <div class="flex">
              <span style="margin-right:10px">每次赠送&nbsp;</span>
              <el-input-number :controls="false" class="input" :min="1" v-model="form.coupon_rules[0].coupon_num" @change="handleNum"></el-input-number>
              <span style="margin-left:10px">张</span>
            </div>
          </el-form-item>
        </div>

        <!-- 消费送 -->
        <div v-if="form.give_type_seq == 40">
          <el-form-item>
            <span slot="label">发放限制</span>
            <el-radio v-model="give_rules.is_all_give_goods_category" :label="1">全部商品</el-radio>
            <el-radio v-model="give_rules.is_all_give_goods_category" :label="0">指定商品</el-radio>
          </el-form-item>

          <el-form-item>
            <span slot="label"></span>
            <div class="flex" v-if="form.allshop == 1">
              <el-button type="default">指定分类</el-button>
              <el-button type="default">指定品牌</el-button>
              <el-button type="default">重置</el-button>
            </div>
          </el-form-item>
          <!-- start -->
          <div v-if="give_rules.is_all_give_goods_category == 0">
            <el-form-item>
            <span slot="label">使用类别</span>
              <el-radio v-model="allProtype" :label="true">全品类</el-radio>
              <el-radio v-model="allProtype" :label="false"> 指定品类</el-radio>
            </el-form-item>
            <el-form-item v-if="!allProtype">
              <div class="checkbox-list">
                <el-checkbox :indeterminate="isIndeterminatetype" v-model="checkTypeAll" class="all"
                  @change="checkAllType">全选</el-checkbox>
                <el-checkbox-group @change="hangleChangeType" style="margin-top:0px;margin-left:20px"
                  v-model="form.give_rules.attr_value_ids">
                  <el-checkbox v-for="item in protypeList" :label="item.goods_type_id" :key="item.goods_type_id">{{
                      item.goods_type_name
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </div>
          <div v-if="give_rules.is_all_give_goods_category == 0">

            <el-form-item>
              <span slot="label">适用品牌</span>
              <el-radio v-model="allBrand" :label="true">全品牌</el-radio>
              <el-radio v-model="allBrand" :label="false"> 指定品牌</el-radio>
            </el-form-item>
            <el-form-item v-if="!allBrand">

              <div class="checkbox-list">
                <el-checkbox :indeterminate="isIndeterminatebrand" class="all" @change="checkAllBrand"
                  v-model="checkBrand">全选</el-checkbox>
                <el-checkbox-group @change="hangleChangeBrand" style="margin-top:0px;margin-left:20px"
                  v-model="form.give_rules.brand_ids">
                  <el-checkbox v-for="item in brandList" :key="item.brand_id" :label="item.brand_id">{{
                      item.brand_name
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </div>
          <!-- end -->

          <el-form-item>
            <div class="flex" style="margin-top:20px">
              <div style="margin-right:20px">
                <span class="mr-10">素金订单</span>
                <el-select v-model="give_rules.is_g" class="select-w-100">
                  <el-option :value="1" label="送券">送券</el-option>
                  <el-option :value="0" label="不送券">不送券</el-option>
                </el-select>
              </div>
              <div style="margin-right:20px">
                <span class="mr-10">素金+非素订单</span>
                <el-select v-model="give_rules.is_g_and_not_g" class="select-w-100">
                  <el-option :value="1" label="送券">送券</el-option>
                  <el-option :value="0" label="不送券">不送券</el-option>
                </el-select>
              </div>

              <div style="margin-right:20px">
                <span class="mr-10">单件非素订单</span>
                <el-select v-model="give_rules.is_not_g" class="select-w-100">
                  <el-option :value="1" label="送券">送券</el-option>
                  <el-option :value="0" label="不送券">不送券</el-option>
                </el-select>
              </div>
              <div style="margin-right:20px">
                <span class="mr-10">多件非素订单</span>
                <el-select v-model="give_rules.is_more_not_g" class="select-w-100">
                  <el-option :value="1" label="送券">送券</el-option>
                  <el-option :value="0" label="不送券">不送券</el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">复购限制</span>
            <div class="flex center">
              <el-checkbox v-model="give_rules.is_repeat_buy" :true-label="1" :false-label="0"></el-checkbox>

              <div class="ml-10" v-if="give_rules.is_repeat_buy">
                <span class="mr-10">复购发券触发间隔</span>
                <el-input class="write-content" v-model="give_rules.interval_day"></el-input>
                <span class="ml-10">天</span>
              </div>
              <div class="ml-10" v-if="give_rules.is_repeat_buy">
                <span class="mr-10">复购</span>
                <el-input class="write-content" v-model="give_rules.interval_num"></el-input>
                <span class="ml-10">次或以上赠送 (每达到对应单数赠送，例：【2】，首单不送，买2\4\6单赠送)</span>
              </div>
            </div>
          </el-form-item>

          <!-- <el-form-item> -->
            <!-- <span slot="label">优惠券选择</span> -->
            <!-- <el-button type="primary" style="align-self:flex-start" @click="handleAddCoupon">新增优惠券</el-button> -->
          <!-- </el-form-item> -->

          <el-form-item>
            <span slot="label"></span>
            <div>
              <div class="flex mb-10 " :key="index" v-for="(item, index) in form.coupon_rules">
                <div class="mr-10">
                  <span class="mr-10">消费额满</span>
                  <el-input class="input" v-model="form.coupon_rules[index].consumption_amount"></el-input>
                  <span>元</span>
                </div>
                <div>
                  <span class="mr-10">赠</span>
                  <el-select multiple v-model="form.coupon_rules[index].coupon_ids">
                    <el-option v-for="item in couponList" :key="item.coupon_id" :label="item.title_name"
                      :value="item.coupon_id + ''"></el-option>
                  </el-select>
                </div>

                <div class="ml-10">
                  <span class="mr-10">每次赠送</span>
                  <el-input-number :min="1" :controls="false" class="input" v-model="form.coupon_rules[index].coupon_num"></el-input-number><span>&nbsp;张</span>
                </div>
                <div class="mr-10">
                  <span class="ml-10">每人只能领取</span>
                  <el-select v-model="form.coupon_rules[index].coupon_limit_num">
                    <el-option :value="0" label="不限"></el-option>
                    <el-option :key="item" :label="item" :value="item" v-for="item in 10">{{
                        item
                    }}</el-option>
                  </el-select><span>&nbsp;次</span>
                </div>
                <div class="ml-20">
                  <el-link class="mr-10" type="primary" @click="handleCoupon(index)" :underline="false">添加</el-link>
                  <el-link v-if="index != 0" type="danger" @click="handleDelCoupon(index)" :underline="false">删除
                  </el-link>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <!-- end -->

        <!-- 手动送券(自定义送券有消息推送) -->
        <div v-if="form.give_type_seq == 10 || form.give_type_seq == 70">
          <el-form-item>
            <span slot="label"></span>
            <div>
              <el-radio @change="chaneTime" v-model="form.days" :label="1">仅一次</el-radio>
              <el-radio @change="chaneTime" v-model="form.days" :label="2"> 按天</el-radio>
              <el-radio @change="chaneTime" v-model="form.days" :label="3">按周</el-radio>
              <el-radio @change="chaneTime" v-model="form.days" :label="4">按月</el-radio>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="flex">
              <div class="flex" v-if="form.days == 2">
                <span>间隔&nbsp;&nbsp;</span>
                <el-input class="short-input ml-10" v-model="give_rules.interval_day"></el-input><span
                  class="ml-10">&nbsp;天发送</span>
              </div>

              <div class="flex ml-10" v-if="form.days == 3">
                <span>间隔&nbsp;&nbsp;</span>
                <el-input class="short-input ml-10" v-model="give_rules.interval_week"></el-input><span
                  class="ml-10">周</span>
              </div>

              <div class="flex" v-if="form.days == 3">
                <span class="ml-10">星期</span>
                <el-select class="ml-10" multiple v-model="give_rules.weeks">
                  <el-option v-for="(item, index) in weekDay" :label="item.label" :value="item.value + ''" :key="index">
                    {{ item.label }}</el-option>
                </el-select>
              </div>

              <div class="flex center" v-if="form.days == 4">
                <span class="mr-10 ">月份</span>
                <el-select v-model="give_rules.months" multiple class="mr-10">
                  <el-option :key="index" v-for="(item, index) in months" :label="item.label" :value="item.value + ''">
                    {{ item.label }}</el-option>
                </el-select>
                <el-radio v-model="give_rules.is_month_day" :label="1">天</el-radio>

              </div>

              <div v-if="give_rules.is_month_day == 1 && form.days == 4">
                <el-select class="ml-10" v-model="give_rules.month_days" multiple>
                  <el-option v-for="item in 31" :value="item" :key="item"> {{ item }}号</el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label"></span>
            <div class="flex">
              <el-date-picker prop="datel" v-model="give_rules.issue_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" >
              </el-date-picker>
              &nbsp;&nbsp;
              <el-checkbox class="ml-10" v-model="give_rules.is_limit" :true-label="1" :false-label="0" v-if="form.days != 1"></el-checkbox>
              <span v-if="form.days != 1">&nbsp; 使用后</span>
              <div class="flex" v-if="give_rules.is_limit&&form.days != 1">
                <el-input class="input ml-20" v-model="give_rules.limit_day"></el-input><span class="ml-20">天再次领取</span>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">领取对象</span>
            <el-radio v-model="form.give_object_seq" :label="10">全部会员</el-radio>
            <!-- <el-radio v-model="form.give_object_seq" :label="20">粉丝</el-radio>
            <el-radio v-model="form.give_object_seq" :label="30">指定会员</el-radio> -->
          </el-form-item>

          <el-form-item>
            <span slot="label">优惠券选择</span>
            <div class="flex">
              <el-select multiple v-model="form.coupon_rules[0].coupon_ids" @change="handleLimitCoupon">
                <el-option v-for="item in couponList" :key="item.coupon_id" :label="item.title_name"
                  :value="item.coupon_id + ''" :disabled="item.remain_num!='不限'&&item.remain_num<=0">
                     <span style="float: left">{{ item.title_name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{' 剩余('+item.remain_num+')'}}</span>
                  </el-option>
              </el-select>
              <!-- <el-button style="align-self:flex-start" class="ml-10" type="primary" @click="handleAddCoupon">新增优惠券
              </el-button> -->
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">赠送张/次</span>
            <div class="flex">
              <div class="felx">
                <span>每次赠送&nbsp;</span>
                <el-input-number :controls="false" :min="1" class="input" v-model="form.coupon_rules[0].coupon_num"></el-input-number>
                <span>张</span>
              </div>
              <!-- <div class="ml-20">
                <span>每人只能领取&nbsp;</span>
                <el-select v-model="form.coupon_rules[0].coupon_limit_num">
                  <el-option :value="item" :key="item" v-for="item in 10">{{ item }}次</el-option>
                </el-select><span>&nbsp;次</span>
              </div> -->
            </div>
          </el-form-item>
        </div>

        <!-- 纪念日送券 -->
        <div v-if="form.give_type_seq == 60">
          <el-form-item>
            <span slot="label"></span>
            <div class="flex center">
              <el-radio v-model="give_rules.is_in_the_day" :label="1">纪念日当日赠送</el-radio>
              <el-radio v-model="give_rules.is_in_the_day" :label="0">纪念日前</el-radio>
              <div v-if="!give_rules.is_in_the_day" class="flex">
                <el-input class="input ml-10 mr-10" v-model="give_rules.day_num"></el-input>天赠送
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">*赠送张/次</span>
            <div class="flex">
              <span class="mr-10">每次赠送</span>
              <el-input-number :min="1" :controls="false" class="input" v-model="form.coupon_rules[0].coupon_num"></el-input-number><span class="ml-10">张</span>
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">优惠券选择</span>
            <div class="flex">
              <el-select multiple v-model="form.coupon_rules[0].coupon_ids" class="mr-10" @change="handleLimitCoupon">
                <el-option v-for="item in couponList" :key="item.coupon_id" :label="item.title_name"
                  :value="item.coupon_id + ''" :disabled="item.remain_num!='不限'&&item.remain_num<=0">\
                     <span style="float: left">{{ item.title_name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{' 剩余('+item.remain_num+')'}}</span>
                  </el-option>
              </el-select>
              <!-- <el-button type="primary" style="align-self:flex-start" @click="handleAddCoupon">新增优惠券</el-button> -->
            </div>
          </el-form-item>
        </div>
        <!-- end -->

        <!-- 生日 送券-->
        <div v-if="form.give_type_seq == 50">
          <el-form-item>
            <span slot="label"></span>
            <div class="flex center">
              <el-radio :label="1" v-model="give_rules.is_in_the_day">生日当日赠送</el-radio>
              <el-radio :label="0" v-model="give_rules.is_in_the_day"> 生日前</el-radio>
              <div class="flex" v-if="!give_rules.is_in_the_day">
                <el-input class="ml-20 input" v-model="give_rules.day_num"></el-input>
                <span class="ml-20">天赠送</span>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">优惠券选择</span>
            <div class="flex">
              <el-select multiple v-model="form.coupon_rules[0].coupon_ids" class="mr-10" @change="handleLimitCoupon">
                <el-option v-for="item in couponList" :key="item.coupon_id" :label="item.title_name"
                  :value="(item.coupon_id+'')" :disabled="item.remain_num!='不限'&&item.remain_num<=0">
                     <span style="float: left">{{ item.title_name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{' 剩余('+item.remain_num+')'}}</span>
                  </el-option>
              </el-select>
              <!-- <el-button type="primary" style="align-self:flex-start" @click="handleAddCoupon">新增优惠券</el-button> -->
            </div>
          </el-form-item>

          <el-form-item>
            <span slot="label">*赠送张/次</span>
            <div class="flex">
              <span class="mr-10">每次赠送</span>
              <el-input-number :controls="false" class="input" :min="1" v-model="form.coupon_rules[0].coupon_num"></el-input-number><span class="ml-10">张</span>
            </div>
          </el-form-item>
        </div>

        <div>
          <el-form-item>
            <span slot="label">优惠券到账方式</span>
            <el-radio @change="changeLinqu" v-model="form.ways_seq" :label="10"
              :disabled="form.give_type_seq == 10 || form.give_type_seq == 20">
              手动领取</el-radio>
            <el-radio @change="changeLinqu" v-model="form.ways_seq" :label="20"
              :disabled="form.give_type_seq == 10 || form.give_type_seq == 20">
              直接到账</el-radio>
          </el-form-item>
        </div>

        <div v-if="form.give_type_seq">
          <el-form-item>
            <span slot="label">微信模板消息推送</span>
            <el-switch :disabled="form.give_type_seq == 10" v-model="form.message_status" :active-value="1"
              :inactive-value="0" active-color="#13ce66" inactive-color="#ccc">
            </el-switch>
          </el-form-item>
        </div>
        <div
          v-if="(form.give_type_seq == 20 || form.give_type_seq == 30 || form.give_type_seq == 40 || form.give_type_seq == 50 || form.give_type_seq == 60 || form.give_type_seq == 70) && form.message_status == 1 && form.list">
          <div v-for="(item, index) in form.list" :key="index">
            <el-form-item label="开始语">
              <div style="display: flex">
                <el-input id="input1" type="textarea" rows="6" style="width: 600px;" clearable="" maxlength="100"
                  show-word-limit v-model="item.data.first.value" placeholder="请输入开始语" />
                <div  class="flex" style="align-items:flex-start">
                <el-input type="color" style="margin-top:5px;  width: 70px; margin-right: 5px;margin-left:20px; cursor: pointer"
                  v-model="item.data.first.color" class="color" />
                <span> {{ item.data.first.color }}</span>

                  <el-select v-if="form.give_type_seq!=20" placeholder="插入参数" v-model="item.data.first.params" style="margin-left: 10px; width: 130px"
                    @change="(val) => handleChangeSelect(val, 'first', 'input1')">
                    <template v-for="item in parameterList">
                      <el-option :key="item.parameter_id" :label="item.value" :value="item.key"></el-option>
                    </template>
                  </el-select>
                  <div v-if="form.give_type_seq!=20" style="color: #909399; font-size: 14px; margin-left: 10px">
                    提示：可往消息内容插入参数
                  </div>
                </div>

              </div>
            </el-form-item>
            <!-- 首次關注送券 -->
            <el-form-item label="操作流程" v-if="form.give_type_seq == 20">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword1.color,
                }" v-model="item.data.keyword1.value" placeholder="#操作流程#" :disabled="true" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword1.color" class="color" />
                  {{ item.data.keyword1.color }}
                </div>
              </div>
            </el-form-item>
            <el-form-item label="客服电话" v-if="form.give_type_seq == 20">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword2.color,
                }" v-model="item.data.keyword2.value" placeholder="#客服电话#" :disabled="false" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword2.color" class="color" />
                  {{ item.data.keyword2.color }}
                </div>
              </div>
            </el-form-item>


            <!-- 消費注冊 -->
            <el-form-item label="注冊用戶" v-if="form.give_type_seq == 30">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword1.color,
                }" v-model="item.data.keyword1.value" placeholder="#注冊用戶#" :disabled="true" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword1.color" class="color" />
                  {{ item.data.keyword1.color }}
                </div>
              </div>
            </el-form-item>


            <el-form-item label="注冊時間" v-if="form.give_type_seq == 30">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword2.color,
                }" v-model="item.data.keyword2.value" placeholder="#注冊時間#" :disabled="true" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword2.color" class="color" />
                  {{ item.data.keyword3.color }}
                </div>
              </div>

            </el-form-item>


            <el-form-item label="注冊來源" v-if="form.give_type_seq == 30">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword3.color,
                }" v-model="item.data.keyword3.value" placeholder="#注冊來源#" :disabled="true" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword3.color" class="color" />
                  {{ item.data.keyword3.color }}
                </div>
              </div>
            </el-form-item>


            <el-form-item label="服务类型" v-if="form.give_type_seq != 20 && form.give_type_seq != 30">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword1.color,
                }" maxlength="30" show-word-limit v-model="item.data.keyword1.value" placeholder="请输入服务类型" />

                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword1.color" class="color" />
                  {{ item.data.keyword1.color }}
                </div>
              </div>
            </el-form-item>

            <el-form-item label="服务状态" required="" v-if="form.give_type_seq != 20 && form.give_type_seq != 30">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword2.color,
                }" maxlength="30" show-word-limit v-model="item.data.keyword2.value" placeholder="请输入服务状态" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword2.color" class="color" />
                  {{ item.data.keyword2.color }}
                </div>
              </div>
            </el-form-item>

            <el-form-item label="服务时间" v-if="form.give_type_seq != 20 && form.give_type_seq != 30">
              <div style="display: flex">
                <el-input :style="{
                  width: '600px',
                  color: item.data.keyword3.color,
                }" v-model="item.data.keyword3.value" placeholder="#推送时间#" :disabled="true" />
                <div style="
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    ">
                  <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                    v-model="item.data.keyword3.color" class="color" />
                  {{ item.data.keyword3.color }}
                </div>
              </div>
            </el-form-item>


            <el-form-item label="结束语" v-if="form.give_type_seq != 20">
              <div style="display: flex">
                <el-input id="input2" type="textarea" rows="6" style="width: 600px" maxlength="100" show-word-limit
                  v-model="item.data.remark.value" placeholder="请输入结束语" />
                <div>
                  <div style="
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                        margin-bottom: 10px;
                      ">
                    <el-input type="color" style="width: 70px; margin-right: 5px; cursor: pointer"
                      v-model="item.data.remark.color" class="color" />
                    {{ item.data.remark.color }}
                  </div>

                  <el-select placeholder="插入参数" v-model="item.data.remark.params"
                    style="margin-left: 10px; width: 130px"
                    @change="(val) => handleChangeSelect(val, 'remark', 'input2')">
                    <template v-for="item in parameterList">
                      <el-option :key="item.parameter_id" :label="item.value" :value="item.key"></el-option>
                    </template>
                  </el-select>
                  <div style="color: #909399; font-size: 14px; margin-left: 10px">
                    提示：可往消息内容插入参数
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item>
              <span slot="label">链接类型 </span>
              <el-radio v-model="item.url_type" :label="'0'" :disabled="form.ways_seq==10">无须跳转</el-radio>
              <el-radio v-model="item.url_type" :label="'1'">自定义链接</el-radio>
              <el-radio v-model="item.url_type" :label="'3'">H5模板</el-radio>
            </el-form-item>

            <el-form-item label="H5链接" required="" v-if="item.url_type == 1">
              <div>
                <el-input v-if="form.list[index].url_type == 1" v-model="form.list[index].url" placeholder="请输入自定义链接"
                  style="width: 600px;" clearable="" />
              </div>
            </el-form-item>

            <el-form-item label="跳转模板" v-if='item.url_type == 3'>
              <el-select v-model="form.jump_id" allow-create filterable placeholder="请选择跳转链接">
                <el-option :label="item.label" v-for="(item, index) in getDefineHrefList" :key="index"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <el-form-item>
          <el-button type="default" @click="goBack">返回</el-button>
          <el-button type="primary" @click="handleSave">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import { getParameterReq, saveReq, readReq } from "@/api/marketing/message";


import { mangePlanSelectCouponList, messageWeiXinData, mangePlanNoticeCreate } from "@/api/marketing/sendCoupon/index";
import _ from "lodash"

export default {
  props: {
    title: {
      type: String,
      default: 'add'
    },
    client_system_id: {
      type: Number,
      default: ""
    },
    opertion_type: {
      type: String,
      default: 'add'
    },
    planItem: {
      type: Object,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },

    protypeList: {
      type: Array,
      default() {
        return []
      }
    },

    brandList: {
      type: Array,
      default() {
        return []
      }
    },
  },

  created() {
    this.getCouponType();
    this.getParameter();
  },

  computed: {
    getDefineHrefList() {
      let arr = this.form.coupon_rules.reduce((prev, next) => {
        prev.push(next.coupon_num * next.coupon_ids.length)
        return prev
      }, []);
      let len = arr.filter(item => item > 1);
      if (len > 0) {
        if (this.form.jump_id == '1') { this.form.jump_id = '' }
        let list = _.cloneDeep(this.defineHrefList);
        list.splice(0, 1);
        if (this.form.ways_seq == 10) {
          let lists = _.cloneDeep(this.defineHrefList);
          lists.pop();
          if(this.form.jump_id=='couponcenter'){
            this.form.jump_id='';
          }
          return lists;
        }
        return list
      } else {
        if (this.form.ways_seq == 10) {
          let list = _.cloneDeep(this.defineHrefList);
          list.pop();
          if(this.form.jump_id=='couponcenter'){
             this.form.jump_id='';
          }
          return list
        }
        return this.defineHrefList;
      }
    }
  },

  data() {
    return {
      allProtype: true,
      allBrand: true,

      form: {
        plan_name: "", //管理者计划名称
        give_type_seq: "", //赠送类型
        give_rules: {
          attr_value_ids: [],
          brand_ids: [],
          is_in_the_day:'',
        },

        ways_seq: 20,

        message_status: 0,

        jump_id: '',

        give_object_seq:10,

        coupon_rules: [
          {
            coupon_ids: [],
            coupon_num: "",
            coupon_limit_num: "",
            consumption_amount: '',
          }
        ],

        days: "",
        day: 1,
        list: [
          {
            time: "",
            timeType: "",
            timeValue: "",
            wechat_message_scenario_id: 0,
            url_type: 1,
            method: 0,
            url: "", // 链接
            data: {
              first: {
                value: "",
                color: "#000000",
                params: ''
              },
              keyword1: {
                value: "",
                color: "#000000"
              },
              keyword2: {
                value: "",
                color: "#000000"
              },
              keyword3: {
                value: "{{推送时间}}",
                color: "#000000"
              },
              keyword4: {
                value: "",
                color: "#000000"
              },
              keyword5: {
                value: "",
                color: "#000000"
              },
              remark: {
                value: "",
                color: "#000000",
                params: ''
              }
            } // 开始语等数据
          }
        ],
      },

      rules: {
        plan_name: [{ required: true, message: "计划名称必填", trigger: "blur" }]
      },

      defineHrefList: [{
        value: '1',
        label: '刮刮乐'
      },
      // {
      //   value: '2',
      //   label: '首次关注',
      // },
      // {
      //   value: '3',
      //   label: '纪念日'
      // }, {
      //   value: '4',
      //   label: '自定义',
      // },
      // {
      //   value: '5',
      //   label: '注册'
      // }, {
      //   value: '6',
      //   label: '生日',
      // },
      {
        value: '7',
        label: '特权'
      },
      {
        value: 'couponcenter',
        label: '会员中心-优惠券'
      }
      ],//定义的链接列表

      parameterList: [],

      give_rules: {
        is_all_give_goods_category: 1,//全部商品
        is_g: 0,
        is_g_and_not_g: 1,
        is_not_g: 0,
        is_in_the_day:1,
        is_more_not_g:1,
        issue_time:""
      },


      isIndeterminatetype: false,
      isIndeterminatebrand: false,
      checkTypeAll: false,
      checkBrand: false,



      message_data: {},

      weekDay: [
        {
          label: "星期一",
          value: 1
        },
        {
          label: "星期二",
          value: 2
        },
        {
          label: "星期三",
          value: 3
        },
        {
          label: "星期四",
          value: 4
        },
        {
          label: "星期五",
          value: 5
        },
        {
          label: "星期六",
          value: 6
        },
        {
          label: "星期日",
          value: 7
        }
      ],

      months: [
        {
          label: "一月份",
          value: 1
        },
        {
          label: "二月份",
          value: 2
        },
        {
          label: "三月份",
          value: 3
        },
        {
          label: "四月份",
          value: 4
        },
        {
          label: "五月份",
          value: 5
        },
        {
          label: "六月份",
          value: 6
        },
        {
          label: "七月份",
          value: 7
        },
        {
          label: "八月份",
          value: 8
        },
        {
          label: "九月份",
          value: 9
        },
        {
          label: "十月份",
          value: 10
        },
        {
          label: "十一月份",
          value: 11
        },
        {
          label: "十二月份",
          value: 12
        }
      ],

      currentMonthDays: [],



      giveSeqType: [
     {
          label: "手动领券",
          value: 10
        },
        {
          label: "首次关注",
          value: 20
        },
        {
          label: "注册送券",
          value: 30
        },
        {
          label: "消费送券",
          value: 40
        }, {
          label: '生日赠送',
          value: 50
        }, {
          value: 60,
          label: '纪念日赠送'
        }, {
          value: 70,
          label: '自定义赠送'
        }
      ],

      couponList: [],

    }
  },

  watch: {
    show: {
      immediate: true,
      handler(val, old) {
        if (val && this.planItem.coupon_issue_id) {
          this.handleEdits(this.planItem);
        } else if (val && !this.planItem.coupon_issue_id) {
          this.handleAddArgs(this.planItem);
          // this.form.give_type_seq=this.planItem.give_type_seq;
          // this.getMessageData();
        }
      }
    }
  },

  methods: {
    handleNum(val) {
    },

    changeLinqu() { },


    getChangTime(give_rules) {
      if (give_rules?.is_only_once) {
        this.form.days = 1
      }
      if (give_rules?.is_day) {
        this.form.days = 2;
      }
      if (give_rules?.is_month) {
        this.form.days = 2;
      }
      if (give_rules?.is_week) {
        this.form.days = 3;
      }

      if (give_rules?.is_month) {
        this.form.days = 4;
      }
    },

    handleEdits(val) {
      this.give_rules = val.give_rules;
      this.getChangTime(val.give_rules);
      this.form.give_rules.brand_ids = val.give_rules.brand_ids ? val.give_rules.brand_ids : [];
      if (this.form.give_rules.brand_ids && this.form.give_rules.brand_ids.length > 0) {
        this.allBrand = false;
      } else {
        this.allBrand = true;
      }
      this.form.give_rules.attr_value_ids = val.give_rules.attr_value_ids ? val.give_rules.attr_value_ids : [];

      if (this.form.give_rules.attr_value_ids && this.form.give_rules.attr_value_ids.length > 0) {
        this.allProtype = false;
      } else {
        this.allProtype = true;
      }

      if (this.give_rules.attr_value_ids) {
        this.hangleChangeType(this.give_rules.attr_value_ids);
      } else {
        this.hangleChangeType([]);
      }

      if (this.give_rules.brand_ids) {
        this.hangleChangeBrand(this.give_rules.brand_ids);
      } else {
        this.hangleChangeBrand([]);
      }


      delete val.give_rules;
      this.form = { ...this.form, ...val };
      if (val.give_type_seq != 10) {
        this.form.list[0].data.first.value = '';
        this.form.list.splice(0, 1, val.message_data);
        Vue.delete(this.form.message_data);
      } else {
        this.form.list[0].url = this.form.message_data.url;
        this.form.list[0].url_type = this.form.message_data.url_type;
        Vue.delete(this.form.message_data);
      }
    },

    handleAddArgs(val) {
      if(val.give_type_seq){
        this.form.give_type_seq=val.give_type_seq;
      }
      delete val.give_rules;
      this.form = { ...this.form, ...val };
      this.getMessageData();
      if (val.give_type_seq != 10) {
        this.form.list=[];
        this.form.list.push(val.message_data)
      }
    },

    // 生日,纪念日,注册，对模板链接的限制，
    handleLimitCoupon(val) {
      console.log(val)
    },

    // 商品類別
    hangleChangeType(value) {
      let checkedCount = value.length;
      this.checkTypeAll = checkedCount == this.protypeList.length;
      this.isIndeterminatetype = checkedCount > 0 && checkedCount < this.protypeList.length;
    },

    hangleChangeBrand(value) {
      let checkedCount = value.length;
      this.checkBrand = checkedCount == this.brandList.length;
      this.isIndeterminatebrand = checkedCount > 0 && checkedCount < this.brandList.length;
    },

    checkAllType(value) {
      this.checkTypeAll = value;
      this.form.give_rules.attr_value_ids = value ? this.protypeList.map(item => item.goods_type_id) : []
      this.isIndeterminatetype = this.form.give_rules.attr_value_ids.length > 0 && this.form.give_rules.attr_value_ids.length < this.protypeList.length;
    },

    checkAllBrand(value) {
      this.checkBrand = value;
      this.form.give_rules.brand_ids = value ? this.brandList.map(item => item.brand_id) : []
      this.isIndeterminatebrand = this.form.give_rules.brand_ids.length > 0 && this.form.give_rules.brand_ids.length < this.brandList.length;
    },
    // 品牌


    getMessageData() {
      this.$emit('changeSeq',this.form.give_type_seq);
      if(this.form.give_type_seq==10){
        this.form.message_status=0;
      }

      if (this.form.give_type_seq == 10 || this.form.give_type_seq == 20) {
        this.form.ways_seq = 10;
      }else{
        if(!this.form.coupon_issue_id){
           this.form.ways_seq=20;
        }
      }

      //默认时生日和纪念日选中
      if((this.form.give_type_seq==50||this.form.give_type_seq==60)&&!this.form.coupon_issue_id){
          if(this.form.give_type_seq==50){
            if(this.give_rules.is_in_the_day===undefined){
              this.give_rules.is_in_the_day=1;
            }
          }
          if(this.form.give_type_seq==60){
            if(this.give_rules.is_in_the_day===undefined){
              this.give_rules.is_in_the_day=1;
            }
          }
      }

      if (this.form.give_type_seq == 10) {
         this.form.message_status = 0; return };

      let data = { give_type_seq: this.form.give_type_seq, client_system_id: this.client_system_id };

      messageWeiXinData(data).then(res => {
        if (res.code == 1) {
          if(Object.keys(res.data.message_data).length>0){
              this.form.list.splice(0,1,res.data.message_data);
          }else{
              this.form.list.splice(0,1,...this.$options.data().form.list);
          }
          if (this.form.list[0].url == "") {
            this.$set(this.form.list[0], 'url_type', "0")
          } else {
            this.$set(this.form.list[0], 'url_type', '1')
          }
          if (!this.form.list[0].data.remark?.params) {
            this.$set(this.form.list[0].data.remark, 'params', '');
          }
          if (!this.form.list[0].data.first?.params) {
            this.$set(this.form.list[0].data.first, 'params', '');
          }
        }
      });
      // 根据券类型自动给跳转链接添加链接地址
    },


    handleAddCoupon() {
      let message_data = null
      if (this.form.give_type_seq == 10) {
        message_data = {};
      } else {
        message_data = this.form.list[0]
      }


      let data = {
        coupon_issue_id: this.form.coupon_issue_id,
        plan_name: this.form.plan_name,
        client_system_id: this.client_system_id,
        give_object_seq: this.form.give_object_seq,
        give_rules: this.give_rules,
        give_type_seq: this.form.give_type_seq,
        coupon_rules: this.form.coupon_rules,
        message_data: message_data,
        jump_id: this.form.jump_id
      }
      let params = _.cloneDeep(data);
      this.$router.push({ path: "/marketing/coupon/couponList/index", query: { isCreateCopuon: true, params } });
    },

    // 获取送券类型
    getCouponType() {
      mangePlanSelectCouponList().then(res => {
        console.log(res);
        if (res.code == 1) {
          // this.couponList = res.data.list.filter(item=>(item.issue_total-item.issue_tally)>0);
          this.couponList = res.data.list;

        }
      });
    },

    goBack() {
      this.$emit("oncolse");
    },

    chaneTime(val) {
      if (val == 1) {
        this.give_rules.is_only_once = 1;
        this.give_rules.is_day = 0;
        this.give_rules.is_week = 0;
        this.give_rules.is_month = 0;
      } else if (val == 2) {
        this.give_rules.is_day = 1;
        this.give_rules.is_only_once = 0
        this.give_rules.is_week = 0
        this.give_rules.is_month = 0;
      } else if (val == 3) {
        this.give_rules.is_week = 1;
        this.give_rules.is_day = 0;
        this.give_rules.is_only_once = 0;
        this.give_rules.is_month = 0;
      } else if (val == 4) {
        this.give_rules.is_month = 1;
        this.give_rules.is_week = 0;
        this.give_rules.is_day = 0;
        this.give_rules.is_only_once = 0;
        this.give_rules.is_month_day = 1;
      }
    },

    changeDayOrweek(val) {
      if (val == 0) {
        this.give_rules.is_month_day = true;
        this.give_rules.is_month_week = false;
      } else if (val == 1) {
        this.give_rules.is_month_week = true;
        this.give_rules.is_month_day = false;
      } else {
        this.give_rules.is_month_week = false;
        this.give_rules.is_month_day = false;
      }
    },

    handleCoupon() {
      this.form.coupon_rules.push({
        coupon_ids: [],
        coupon_num: "",
        coupon_limit_num: '',
        consumption_amount: ""
      });
    },

    handleDelCoupon(index) {
      this.form.coupon_rules.splice(index, 1)
    },

    async handleChangeSelect(val, key, id) {
      if (key == 'first') {
        this.form.list[0].data.first.value = this.form.list[0].data.first.value + val;
        this.form.list[0].data.first.params = "";
      }
      if (key == 'remark') {
        this.form.list[0].data.remark.value = this.form.list[0].data.remark.value + val;
        this.form.list[0].data.remark.params = "";
      }
    },

    getParameter() {
      getParameterReq().then(res => {
        if (res.code === 1) {
          this.parameterList = res.data;
        }
      });
    },
    changeRadio() {

    },

    handleSave() {
      if (this.form.give_type_seq == 40 && this.give_rules.is_repeat_buy == 1) {
      if(!this.give_rules.interval_day&&!this.give_rules.interval_num){
           this.$message.warning("限制复购必选");
           return
      }

      }
      let message_data = null
      if (this.form.give_type_seq == 10) {
        message_data = {};
      } else {
        message_data = this.form.list[0]
      }

      if (message_data.url_type == 1 || message_data.url_type == 0) {
        this.form.jump_id = ''
      }

      if (this.allBrand) {
        this.form.give_rules.brand_ids = [];
      }

      if (this.allProtype) {
        this.form.give_rules.attr_value_ids = [];
      }

      let data = {
        coupon_issue_id: this.form.coupon_issue_id,
        plan_name: this.form.plan_name,
        client_system_id: this.client_system_id,
        give_object_seq: this.form.give_object_seq,
        give_rules: { ...this.form.give_rules,...this.give_rules  },
        give_type_seq: this.form.give_type_seq,
        coupon_rules: this.form.coupon_rules,
        jump_id: this.form.jump_id,
        message_data: message_data,
        ways_seq: this.form.ways_seq,
        message_status: this.form.message_status,
        status:this.form.status,
      }
      console.log(data,"data")


      mangePlanNoticeCreate(data).then(res => {
        if (res.code == 1) {
          this.$message.success("创建成功");
          this.$emit("success");
        }
        if (res.code == 2) {
          // this.$message.error(res.message);
        }
      }).catch(err => {
        this.$message.error(err.data.message);
      })
    },
  }
}

</script>
<style lang="less" scoped>
/deep/ .el-page-header__title {
  color: #565656;
}

/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}

.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}

.content {
  height: 80px;
  display: flex;
  align-items: center;
}

.main {
  margin-top: 20px;
  background-color: #fff;
  display: flex;
  padding: 20px 0 0 0;
  height: calc(100vh - 210px);
  overflow: auto;
}

.left {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    padding-left: 120px;
    line-height: 40px;
    text-align: left;
  }
}

.right {
  flex: 1;
}

.bottom {
  margin-top: 30px;
}

.flex {
  display: flex;
}

.input {
  width: 200px;
}

.textarea {
  width: 400px;
}

.select {
  margin-left: 20px;
}

.write-content {
  width: 100px;
  border: none;
  border-bottom: 1px solid #000;

  input {
    border: none
  }
}

.short-input {
  width: 100px;
}

.select {
  width: 150px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.center {
  align-items: center;
}

.select-w-100 {
  width: 100px;
}


.select-w-200 {
  width: 200px
}

.w500 {
  width: 700px;
}

.color {
  width: 24px !important;
  height: 28px;

  /deep/ .el-input__inner {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.checkbox-list {
  display: flex;
  max-width: 700px;
  align-items: top;

}
</style>
