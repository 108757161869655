import { get, post } from '@/utils/request';

// 获取已授权的公众号列表
const getWeChatMessageListReq = (data) => get({
  url: '/store/WeChatMessage.Index/list',
  data: data
});

// 获取授权地址
const getAuthRedirectReq = (data) => get({
  url: '/store/WeChatMessage.Index/getAuthRedirect',
  data: data
});

// 获取模板列表
const getTemplateListReq = (data) => get({
  url: '/store/WeChatMessage.Index/templateList',
  params: data
});

// 模板删除
const deleteTemplateReq = (data) => post({
  url: '/store/WeChatMessage.Index/deleteTemplate',
  data: data
});

// 获取模板消息列表
const getWeChatMessageList = (data) => get({
  url: '/store/WeChatMessage.Scenario/list',
  params: data
});

// 模板消息状态开关
const changeSwitchReq = (data) => post({
  url: '/store/WeChatMessage.Scenario/start',
  data: data,
});

// 删除模板消息
const deleWeChatMessageReq = (data) => post({
  url: '/store/WeChatMessage.Scenario/delete',
  data: data,
});

// 获取可插入参数
const getParameterReq = (data) => get({
  url: '/store/WeChatMessage.Scenario/parameter',
  params: data,
});

// 保存模板
 const saveReq = (data) => post({
  url: '/store/WeChatMessage.Scenario/save',
  data: data,
});

// 查看模板
const readReq = (data) => get({
  url: '/store/WeChatMessage.Scenario/read',
  params: data,
});

// 同步
const synchronousReq = (data) => post({
  url: '/store/WeChatMessage.Index/synchronous',
  data: data,
});

// 统计折线图
const coordinatesLogReq = (data) => get({
  url: '/store/WeChatMessage.Scenario/coordinatesLog',
  params: data,
});

// 推送明细
const detailLogReq = (data) => get({
  url: '/store/WeChatMessage.Scenario/detailLog',
  params: data,
});

export {
  detailLogReq,
  coordinatesLogReq,
  synchronousReq,
  readReq,
  saveReq,
  getParameterReq,
  deleWeChatMessageReq,
  getWeChatMessageList,
  changeSwitchReq,
  deleteTemplateReq,
  getTemplateListReq,
  getWeChatMessageListReq,
  getAuthRedirectReq,
}
