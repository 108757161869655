<template>
  <div class="wrap">
    <div v-if="!show">
      <div class="content">
        <div class="top ">
          <div class="all-number" v-if="bindWchatList.length > 0">
            <el-image class="img" :src="bindWchatList.length > 0 && bindWchatList[checkedTiXi].head_img"></el-image>
            <span class="title">{{ bindWchatList.length > 0 && bindWchatList[checkedTiXi].wechat_name }}</span>
            <el-link class="link" type="primary" @click="handleTogggle" :underline="false">切换体系</el-link>
          </div>
        </div>
        <div v-if="bindWchatList[checkedTiXi] && bindWchatList[checkedTiXi].is_authorization == 0"
          style="width:100%;box-sizing:border-box;margin-left:20px;margin-bottom:20px;display:flex;align-items:center;">
          {{ bindWchatList[checkedTiXi].is_authorization == 1 ? '' : '当前未授权' }}<el-link class="link" type="primary"
            @click="jumptoBind" :underline="false">去绑定</el-link>
        </div>
        <div style="box-sizing:border-box;margin-left:20px">
          说明：为保证所有功能的正常使用，授权时请把所有权限统一授权给E店宝
        </div>
      </div>

      <div class="mains">
        <div class="left">
          <span :class="{ active: form.give_type_seq == '' ? true : false }" @click="handleChecked('')">全部计划</span>
          <span :class="{ active: form.give_type_seq == 30 ? true : false }" @click="handleChecked(30)">注册送券</span>
          <span :class="{ active: form.give_type_seq == 40 ? true : false }" @click="handleChecked(40)">消费送券</span>
          <span :class="{ active: form.give_type_seq == 10 ? true : false }" @click="handleChecked(10)">手动领券</span>
          <span :class="{ active: form.give_type_seq == 50 ? true : false }" @click="handleChecked(50)">生日赠送</span>
          <span :class="{ active: form.give_type_seq == 20 ? true : false }" @click="handleChecked(20)">首次关注</span>
          <span :class="{ active: form.give_type_seq == 60 ? true : false }" @click="handleChecked(60)">纪念日赠送</span>
          <span :class="{ active: form.give_type_seq == 70 ? true : false }" @click="handleChecked(70)">自定义赠送</span>
        </div>
        <el-scrollbar class="scroll">
          <div class="right">
            <el-form :inline="true" style="background:#fff;padding: 20px 0 0 0;">
              <el-button type="primary" style="margin:0 20px"
                @click="() => handleShow({ give_type_seq: form.give_type_seq })">新增送券规则</el-button>

              <el-form-item>
                <el-input placeholder="请输入规则/券名称" v-model.trim="form.search" clearable></el-input>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="form.give_type_seq" clearable>
                  <el-option :value="item.value" :label="item.label" :key="index" v-for="(item, index) in typeSeqList">
                    {{ item.label }}</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="form.status" clearable>
                  <el-option :value="10" label="正常">正常</el-option>
                  <el-option :value="20" label="禁用">禁用</el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleQuery">查询</el-button>
                <el-button type="default" @click="handleReset">重置</el-button>
              </el-form-item>
            </el-form>

            <div class="table" v-loading="loading">
              <el-table stripe :data="planList">
                <el-table-column type="index"></el-table-column>
                <el-table-column label="计划标题" prop="plan_name"></el-table-column>
                <el-table-column label="计划编号" prop="plan_sn" width="200px"></el-table-column>
                <el-table-column label="类型" :formatter="getTypeSeq" prop="give_type_seq" width="120"></el-table-column>
                <el-table-column label="是否启用" width="80">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value="10" :inactive-value="20"
                      @change="row => handleSwitch(scope.row)"></el-switch>
                  </template>
                </el-table-column>

                <el-table-column label="创建人" prop="store_username"  width="100"></el-table-column>
                <el-table-column label="修改人" prop="update_username"  width="100"></el-table-column>
                <el-table-column label="创建时间" prop="create_time" width="160"></el-table-column>
                <el-table-column label="修改时间" width="160" prop="update_time">
                </el-table-column>


                <el-table-column label="操作" fixed="right" width="200">
                  <template slot-scope="scope">
                    <div class="flex">
                      <el-link type="primary" style="margin-right:20px" @click="handleEdit(scope.row)">编辑</el-link>
                      <el-link type="danger" style="margin-right:20px" @click="handleDelete(scope.row)">删除</el-link>

                      <!-- <div>
                        <el-popover style="margin-right:20px" placement="right" width="150px" trigger="click"
                          @hide="() => handleColseEweima()"
                          @show="() => handleErcode(scope.row.message_data.url, 'qrCodeDiv' + scope.row.plan_sn)">
                          <div :ref="'qrCodeDiv' + scope.row.plan_sn" :id="'qrCodeDiv' + scope.row.plan_sn"
                            class="erweima">
                          </div>
                          <el-link slot="reference">二维码</el-link>
                        </el-popover>
                      </div> -->
                      <!-- <el-link style="margin-right:20px" @click="() => handleCopy(scope.row.message_data.url)" id="copy"
                        :underline="false">复制链接
                      </el-link> -->
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="pagina">
              <el-pagination background :current-page="listQuery.page" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :page-sizes="[10, 20, 50, 100, 200, 400]"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <EditSend  @changeSeq="setGiveTypeSeq"   ref="send" :checkSeq="form.give_type_seq" :brandList="brandList" :protypeList="protypeList" v-if="show"
      @success="handleSuccess" :planItem="planItem" :opertion_type="opertion_type" :show="show" @oncolse="handleCancle"
      :title="opertion_type" :client_system_id="form.client_system_id"></EditSend>
    <el-dialog title="选择体系" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div class="list">
        <div class="list-item" :class="checkedTiXiCopy == index ? 'actives' : ''" v-for="(item, index) in bindWchatList"
          :key="item.client_system_id" @click="() => handleCheckedTixi(item.client_system_id)">
          <el-image class="img" :src="item.head_img ? item.head_img : ''"></el-image>
          <span class="mt-20 font-weight-bold">{{ item.wechat_name }}</span>
          <span class="mt-20 font-weight-bold">{{ item.title }}</span>
          <div class="mt-20"><span>状态:&nbsp;&nbsp;&nbsp;</span><span>{{ item.is_authorization == 0 ? '未绑定' : '已绑定'
          }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="hangleTiXi">确定切换</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import EditSend from "./editSendCopuon.vue";
import ClipboardJS from "clipboard";
import QRCode from "qrcodejs2";
import { getListReq } from "@/api/goods/goodsConfig/classify";
import { getBrandList } from "@/api/system/goldPrice/list";

import { mangeSendPlanList, mangePlanUpdate, mangePlanDelete, mangePlanDetail, systemBindingList } from "@/api/marketing/sendCoupon/index";

export default {
  name: "send",
  components: {
    EditSend
  },
  data() {
    return {

      code: null,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0,

      show: false,
      dialogVisible: false,

      checkedTiXi: 0,
      checkedTiXiCopy: 0,

      form: {
        search: "",
        give_type_seq: "",
        status: "",
        valid_start_time: "",
        valid_end_time: "",
        status: "",
        client_system_id: ''
      },

      // 编辑类型
      opertion_type: "add",

      loading: false,

      planList: [],

      planItem: {},

      bindWchatList: [],

      typeSeqList: [
        {
          label: "手动领券",
          value: 10
        },
        {
          label: "首次关注",
          value: 20
        },
        {
          label: "注册送券",
          value: 30
        },
        {
          label: "消费送券",
          value: 40
        }, {
          label: '生日赠送',
          value: 50
        }, {
          value: 60,
          label: '纪念日赠送'
        }, {
          value: 70,
          label: '自定义赠送'
        }
      ],

      brandList: [],
      protypeList: [],
    };
  },

  created() {

    this.getProtypeList();
    this.getBrand();
    // this.getList();
  },

  activated() {
    let { query } = this.$route;
    if (query && query.isCreateCopuon) {
      if (query.opertion_type == 'add') {
        this.handleShow(query.params);
      } else {
        this.handleEdit(query.params);
      }
    }
  },

  methods: {
    getBrand() {
      getBrandList().then(res => {
        console.log(res)
        if (res.code == 1) {
          this.brandList = res.data;
          this.systemBindingLists();
        }
      });
    },

    getProtypeList() {
      getListReq().then(res => {

        if (res.code == 1) {
          this.protypeList = res.data;
        }
      });
    },


    handleCopy(val) {
      const clipboard = new ClipboardJS("#copy", {
        text() {
          return val;
        }
      });
      this.$message.success("复制成功");
    },


    handleCheckedTixi(val) {
      console.log(val)
      let index = this.bindWchatList.findIndex(item => item.client_system_id === val);
      console.log(index)
      this.checkedTiXiCopy = index;
    },

    hangleTiXi() {
      console.log(this.bindWchatList[this.checkedTiXiCopy].client_system_id)
      // console.log(this.bindWchatList)
      // console.log( this.bindWchatList[this.checkedTiXi].client_system_id);

      this.form.client_system_id = this.bindWchatList[this.checkedTiXiCopy].client_system_id;
      localStorage.setItem('sendClient_system_id', this.bindWchatList[this.checkedTiXiCopy].client_system_id);//存储体系id
      localStorage.setItem('sendIndex', this.checkedTiXiCopy);//选择存储体系下标
      this.checkedTiXi = this.checkedTiXiCopy;
      this.dialogVisible = false;
      this.listQuery.page = 1;
      this.getList();
    },

    handleCancle() {
      this.show = false;
    },

    jumptoBind() {
      this.$router.push("/member/system/memberSystemBindList/index");
    },

    handleTogggle() {
      this.dialogVisible = true;
    },

    handleClose() {
      this.dialogVisible = false;
      this.checkedTiXiCopy = this.checkedTiXi;
    },

    systemBindingLists() {
      systemBindingList().then(res => {
        console.log(res);
        this.bindWchatList = res.data.list;
        let Client_system_id=localStorage.getItem('sendClient_system_id');//存储体系id
        let index=localStorage.getItem('sendIndex');//选择存储体系下标
        if(index!=""&&index!=undefined&&index!='undefined'){
            if(res.data.list[index].client_system_id==Client_system_id){
              console.log(index)
              this.checkedTiXi=index;
              this.checkedTiXiCopy=index;
              this.form.client_system_id = Client_system_id;
            }else{
              this.form.client_system_id = res.data.list[0].client_system_id;
            }
        }else{
          this.form.client_system_id = res.data.list[0].client_system_id;
        }

        this.getList()
      })
    },

    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      console.log(this.form)
      mangeSendPlanList({ ...this.listQuery, ...this.form })
        .then(res => {
          if (res.code == 1) {
            this.planList = res.data.list;
            this.loading = false;
            this.total = res.data.total;
          }
          if (res.code == 2) {
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },

    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.code = null;
      this.getList();
    },

    handleSwitch(row) {
      let { coupon_issue_id, status } = row;
      let copyStatus = status
      mangePlanUpdate({ coupon_issue_id, status: copyStatus }).then(res => {
        if (res.code == 1) {
          this.getList();
        }
      });
    },

    handleDelete(row) {
      let { coupon_issue_id } = row;
      this.$confirm('此操作将删除改条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => mangePlanDelete({ coupon_issue_id })).then(() => {
        this.getList();
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    handleEdit(row) {
      let { coupon_issue_id } = row;
      this.opertion_type = 'edit';

      mangePlanDetail({ coupon_issue_id }).then(res => {
        if (res.code == 1) {
          this.planItem = res.data;
          this.show = true;
        }
      })
    },

    handleShow(val) {
      this.show = true;
      this.opertion_type = 'add';
      console.log(val, "val")
      if (val) {
        this.planItem = { ...val }
      } else {
        this.planItem = {};
      }

    },

    handleQuery() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReset() {
      let client_system_id = this.form.client_system_id;
      let formDefault = this.$options.data().form;
      this.form = { ...formDefault, client_system_id };
      this.listQuery.page = 1;
      this.getList();
      this.getList();
    },

    handleChecked(val) {
      this.form.give_type_seq = val;
      this.planItem.give_type_seq = val;
      this.listQuery.page = 1;
      this.getList();
    },

    handleSuccess() {
      this.listQuery.page = 1;
      this.show = false;
      this.getList();
    },
    getTypeSeq(row) {
      return this.typeSeqList.find(item => item.value == row.give_type_seq)?.label;
    },

    handleErcode(val, element) {
      document.getElementById(element).innerHTML = "";
      this.code = new QRCode(element, {
        text: val,
        width: 100,
        height: 100,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
      });

    },
    handleColseEweima() {
      this.code = null;
    },

    setGiveTypeSeq(val){
      this.form.give_type_seq=val;
      this.listQuery.page=1;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.mains {
  display: flex;
  height: calc(100vh - 325px);
}

.title {
  font-size: 25px;
  font-weight: bolder;
  margin: 20px;
}

.left {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-right: 10px;
  padding: 10px;

  span {
    box-sizing: border-box;
    line-height: 30px;
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }
}

.scroll {
  flex: 1;
  height: 100%;

}


.right {
  width: 100%;
}

.top {
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 20px 0;

  .all-number {
    margin-left: 20px;
    margin-right: 100px;
    display: flex;
    align-items: center;
  }
}

.bottom {
  margin-top: 30px;
}

.sub-title {
  margin-bottom: 40px;
}

.flex {
  display: flex;
}

.input {
  width: 200px;
}

.textarea {
  width: 400px;
}

.select {
  margin-left: 20px;
}

.write-content {
  width: 100px;
  border: none;
  border-bottom: 1px solid #000;
}

.short-input {
  width: 100px;
}

.select {
  width: 150px;
}

.active {
  background: #ecf5ff;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.lists-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
}

.list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: rgba(236, 239, 245, 1);
  margin-right: 10px;
  padding: 20px 40px;
  border-radius: 10px;
}

.img {
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.mt-20 {
  margin-top: 20px
}

.font-weight-bold {
  font-weight: bold;
}

/deep/ .el-dialog__footer {
  display: flex;
  justify-content: center;
}

.actives {
  border: 1px solid #409EEE
}
</style>
<style >
.el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

.erweima {
  width: 100px;
  height: 100px;
}
</style>

