import { post } from '@/utils/request';

// 新建今日金价
const createReq = (params) => post({
  url: '/store/system.GoldPrice/create',
  data: {
    ...params,
  },
});

// 列表
const getListReq = () => post({
  url: '/store/system.GoldPrice/list',
});

// 材质成色
const getMaterialList = () => post({
  url: '/store/system.GoldPrice/material_list',
});

const getBrandList=()=>  post({
  url:'/store/system.GoldPrice/getBrandList'
})

export {
  createReq,
  getListReq,
  getMaterialList,
  getBrandList
};
